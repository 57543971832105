<template> 
    <div class="main">
        <p style="font-size: 2000%; color: white; margin-bottom: 10%;">404</p>
    </div>
</template>

<script>

export default {
	data() {
        return {
        }
    },
    async created() {
	},
	mounted() {

    },
    methods: {

    },
	computed: {
	},
    components: {
    }
}
</script>
<style>

.main{
    width: 100%;
    height: 100vh;
    background-color: #1d3557;
    display: flex;
    justify-content: center;
    align-items: center;
}
 
</style>
<template> 
    <Toast />
    <!-- intro -->
    <section class="intro" :style="fondoIntro" >
        <div class="overlay"></div>
        <div class="principal">
            <h1 style="color: white; font-size: 3rem; text-align: center" >Marketing service with NFC cards</h1>
            <p style="color: white; font-size: 1.5rem; text-align: center; margin-top: 3rem;">With our services, you will get personalized NFC cards that will skyrocket your revenue.</p>
        </div>
        <div class="intro-bottom">
            <div class="button-container">
                <a href="#contact" class="cta-button"><h3>Contact with us</h3></a>
            </div>
            <div class="imagen-intro">
                <img src="images/cards.png" alt="cards">
            </div>
        </div>
    </section>
    <!-- intro -->

    <!-- cuerpo -->
    <section class="cuerpo">
        <h1 style="color: black; font-size: 3rem; text-align: center" >What do we do?</h1>
        <div class="principal">
            <div class="principal-block">
                <div class="block-image" :style="fondoCamera"></div>
                <div class="title">
                    <h4 style="color:rgb(0, 52, 77);">Study you business</h4>
                </div>
                <div class="principal-body">
                    <p>Analize business's goals</p>
                    <p>Evaluate target audience</p>
                    <p>Study personal branch</p>
                </div>
            </div>
            <div class="arrow"><i style="font-size: x-large;" class="pi pi-arrow-right"></i></div>
            <div class="principal-block">
                <div class="block-image" :style="fondoDesign"></div>
                <div style="color:rgb(0, 52, 77);" class="title">
                    <h4>Design your NFC cards</h4>
                </div>
                <div class="principal-body">
                    <p>Cards linked to Google reviews</p>
                    <p>Proffesional recommendations</p>
                    <p>Specialized designers</p>
                </div>
            </div>
            <div class="arrow"><i style="font-size: x-large;" class="pi pi-arrow-right"></i></div>
            <div class="principal-block">
                <div class="block-image" :style="fondoClients"></div>
                <div class="title">
                    <h4 style="color:rgb(0, 52, 77);">Boosting your clients</h4>
                </div>
                <div class="principal-body">
                    <p>Boost Business Visibility</p>
                    <p>Enhance Customer Engagement</p>
                    <p>Streamline Customer Feedback</p>
                </div>
            </div>
        </div>
    </section>
    <!-- cuerpo -->
    <!-- Video -->
    <section class="video"> 
        <h1 style="color: black; font-size: 3rem; text-align: center" >How do the cards work?</h1>
        <div class="card-video">
            <div class="video-columna-izquierda">
                <div class="container-video">
                    <video class="vid" controls>
                        <source src="images/HOME_PAGE_VIDEO.mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>

            </div>
            <div class="video-columna-derecha">
                <div class="content">
                    <div class="feature">
                        <i style="margin-right:10px;" class="pi pi-wifi rotated"></i>
                        <p>
                            Contactless Payments
                        </p>
                    </div>
                    <div class="feature">
                        <i style="margin-right:10px;" class="pi pi-desktop"></i>
                        <p>
                            Access Control
                        </p>
                    </div>
                    <div class="feature">
                        <i style="margin-right:10px;" class="pi pi-envelope"></i>
                        <p>
                            Information Sharing
                        </p>
                    </div>
                    <div class="feature">
                        <i style="margin-right:10px;" class="pi pi-user-plus"></i>
                        <p>
                            Customer Engagement
                        </p>
                    </div>
                    <div class="feature">
                        <i style="margin-right:10px;" class="pi pi-globe"></i>
                        <p>
                            Product Authentication
                        </p>
                    </div>
                </div>
            </div>
        </div>


    </section>
    <!-- Video -->
    <!-- formulario -->
    <section id="contact" class="formulario">
        <div class="columnaformulario columnaformulario-izquierda">
            <div class="columna-izquierda-formulario" style="padding-left:20%">
                <h1 style="color:white">Are you interested?</h1>
                <h3 style="color:white">Contact with us</h3>

                <span style="display: flex;flex-wrap: nowrap;" >
                    <p style="margin-right:1%;color:white;">Name</p>
                    <InputText style="width:74%" id="Nombre" type="text" v-model="nombre" />
                    
                </span>
                <span class="" style="display: flex; flex-wrap: nowrap; margin-top:2%">
                    <p style="margin-right:3%;color:white;">Email</p>
                    <InputText style="width:72%" id="email" type="text"  v-model="email"/>
                    
                </span>
                <p style="color:white;margin-top:2%">Ask us your questions</p>
                <Textarea class="textarea-formulario" style="width:80%" rows="5" cols="5" v-model="message"/>
                <div class="boton-rojo-formulario" @click="enviarEmail">
                    <h1 class ="style-boton-rojo-formulario" style="color:#1D3557;text-align:center;padding-top:5%">Submit</h1>
                </div>
            </div>

        </div>
        <div class="columnaformulario columna-derecha-formulario" :style="fondoContactUs">
            
        </div>
    </section>
    <!-- formulario -->
   
</template>

<script>
import UserService from "../service/UserService";
export default {
	data() {
        return {
            
            imageredes: { backgroundImage: "url(./images/fotoredes.png)" },
            fondoIntro: {backgroundImage: "url(./images/fondoIntro.jpg)"},
            fondoCamera: {backgroundImage: "url(./images/camera.png)"},
            fondoDesign: {backgroundImage: "url(./images/design.png)"},
            fondoClients: { backgroundImage: "url(./images/clients.png)" },
            fondoContactUs: {backgroundImage: "url(./images/contact-us.png)"},
            currentSlide: 0,
            textos: [
                "No me aclaraba con mi proyecto, tenia muchas ideas pero no canseguía avanzar. Gracias a BeeLasy en minutos tenia todo el desarrollo.",
                "Como vi q costaba tan poco pensé que no perdía mucho. Aprobé con notaza.",
                "Me la recomendó un compañero de clase y se quedó cortísimo, rapidísimo.",
                "Estaba super agobiada con montones de exámenes y ya no me daba tiempo para nada, un sobre super guay.",
                "Entre la casa, el trabajo, la familia y los estudios estaba pensando en tirar la toalla, y me hablaron de BeeLasy. El mejor consejo de mi vida.",
            ],
            autores: [
                "Marcos de León- Empresariales",
                "Álvaro Sevilla - Psicología",
                "Gorka Sevilla - Psicología",
                "Esther - Bachillerato Segovia",
                "Ana - Filología",
            ],
            nombre:"",
            message:"",
            email:"",
        }
        
    },
    userService: null,
    async created() {
        this.userService = new UserService;
	},
	mounted() {

        setInterval(this.nextSlide, 5000);
    },
    methods: {
        nextSlide() {
            if (this.currentSlide === this.textos.length - 1) {
                this.currentSlide = 0;
            } else {
                this.currentSlide++;
            }
        },
        async enviarEmail(){

            if (this.email=="" || this.name=="" || this.message=="") {
                this.$toast.add({severity:'error', summary: 'El nombre, el mensaje o el email están vacíos',  life: 3000});
            }else{
                try{
                    await this.userService.sendUserQuestionToBeelasy(this.nombre,this.message,this.email);
                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'Se ha enviado corréctamente', life: 3000});
                }catch{
                    this.$toast.add({severity:'error', summary: 'Ha habido un error en el mensaje',  life: 3000});
                }
            }
        }
    },
	computed: {
	},
    components: {
    }
}
</script>
<style>
     /*  layout main container controla el padding del topbar y el footbar */
     /*intro ----------------------------------------------------------intro*/
    .intro{
        width: 100%;
        background-size: 100% 100%;
        height:calc(100vh - 2rem);
        /* background: linear-gradient(to right, #ffffff, #31A76B); */
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        flex-direction: column;

    }

    .intro .overlay{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.65); /* Oscurece la imagen de fondo */
        border-radius: 10px; /* Mantiene los bordes redondeados */
        z-index: 1; /* Coloca la capa por encima de la imagen de fondo */
    }

    .intro .principal{
        width: 50%;
        height: 40%;
        z-index: 2;
    }

    .intro-bottom{
        width: 70%;
        height: 50%;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .button-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .cta-button {
        width: 17rem;
        height: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem 2rem;
        background-color: #31A76B;
        color: white;
        text-decoration: none;
        border-radius: 50px;
        font-size: 1.2rem;
        font-weight: bold;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        transition: background-color 0.3s ease, box-shadow 0.3s ease;
    }

    .cta-button:hover {
        background-color: #288a58;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    }

    .imagen-intro {
        width: 40%;
        max-width: 500px;
        margin-left: 3rem;
        z-index: 2;
    }

    .imagen-intro img {
        width: 100%;
        height: auto;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

    /*intro ----------------------------------------------------------intro*/
    
    /*cuerpo ---------------------------------------------------------cuerpo*/
    .cuerpo{
        width: 100%;
        height:calc(100vh - 2rem);
        background-color:#F5F5F5;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        flex-direction: column;
     
    }

    .cuerpo .principal{
        width: 93%;
        height: 75%;
        background: #31A76B;
        border-radius: 10px;
        margin-top: 2rem;
        border: 1px solid black;
        box-shadow: 0 4px 8px rgba(0, 0, 0.4, 0.4);
        background:linear-gradient(to right, #ffffff, #31A76B);
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
   
    .principal-block{
        width: 23%;
        height: 80%;
        background-color: white;
        border: 1px solid black;
        box-shadow: 0 4px 8px rgba(0, 0, 0.4, 0.4);
        border-radius: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }

    .principal-block:hover {
        transform: scale(1.1); /* Agranda el contenedor al pasar el ratón */
        transition: transform 0.3s ease;
    }

    .principal-block .block-image{
        width: 100%;
        height: 30%;
        
        background-size: contain; 
        background-position: center;  
        background-repeat: no-repeat;
        background-color: #F5F5F5;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .principal-block .title{
        width: 100%;
        height: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid black;
        background-color: #F5F5F5;
    }

    .principal-body{
        width: 100%;
        height: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: large;
    }

    .arrow{
        width: 8%;
        aspect-ratio: 1 / 1;
        background-color: #31A76B;
        border: 1px solid black;
        border-radius: 40px;
        color: white;
        box-shadow: 0 4px 8px rgba(0, 0, 0.4, 0.4);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    /*cuerpo ---------------------------------------------------------cuerpo*/
    /*video ---------------------------------------------------------video*/
    .video {
        width: 100%;
        height:calc(100vh - 2rem);
        background-color: #F5F5F5;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .card-video{
        width: 90%;
        height: 70%;
        margin-top: 2rem;
        background-color: white;
        border: 1px solid black;
        border-radius: 40px;
        box-shadow: 0 4px 8px rgba(0, 0, 0.4, 0.4);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .video-columna-izquierda{
        width: 70%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .container-video{
        width: 75%;
        height: 80%;
        box-shadow: 0 26px 17px rgba(0, 0, 0.8, 0.8);
        }

    .vid{
        width: 100% ;
        height: 100% ;
    }

    .video-columna-derecha{
        width: 30%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .video-columna-derecha .content{
        width: 90%;
        height:80%;
        margin-right: 10%;
        background-color: #F5F5F5;
        border: 1px solid black;
        border-radius: 40px;
        box-shadow: 0 26px 17px rgba(0, 0, 0.6, 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 1.7rem;


    }

    .feature{
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
    }

    .rotated{
        transform: rotate(90deg);
    }


    /*video ---------------------------------------------------------video*/
    /*formulario ---------------------------------------------------------formulario*/
    .formulario{
        width: 100%;
        height:calc(100vh - 2rem);
        background-color:#457B9D;
        display: flex;
        flex-direction: row;
    }

    .columnaformulario{
        height: 100%;
        display:flex;
        flex-direction: column;
        justify-content:center;

    }

    .columnaformulario-izquierda{
        width: 60%;
    }

    .columna-izquierda-formulario{
        width: 100%;
        height: 100%;
        display:flex;
        flex-direction: column;
        justify-content:center;
    }

    .columna-derecha-formulario{
        width: 40%;
        background-size: contain; 
        background-position: center;  
        background-repeat: no-repeat;
    }

    .boton-rojo-formulario{
        width:35%;
        height:5rem;
        background-color:#FCC200;
        border-radius:20px;
        cursor: pointer;
        margin-top: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .boton-rojo-formulario:hover{
        background-color: #ccc;
    }

    /*formulario ---------------------------------------------------------formulario*/
    
     
</style>